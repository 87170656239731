<template>
  <div>
    <b-row class="match-height headerContainer mt-2">
      <b-col cols="3">
        <b-card class="headerContainerItem">
          <p class="app-title date-range mb-0 mt-1">
           Date From {{ this.dateRange.startDate | date }} to
            {{ this.dateRange.endDate | date }}
          </p>
          <date-range-picker ref="picker" opens="right" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :showWeekNumbers="false" :showDropdowns="true" :autoApply="true" v-model="dateRange" @update="updateValues"
            :dateFormat="dateFormat" control-container-class="picker-controller" :ranges="dateRanges"
            :auto-apply="false" :readonly="false" :maxDate="new Date(new Date().setDate(new Date().getDate() - 1))"  class="facebookDateSelector">
            <template v-slot:input="picker">
              <feather-icon icon="CalendarIcon" size="16" class="mr-1"></feather-icon>
              {{ formatDateDisplay(picker.startDate) }} -
              {{ formatDateDisplay(picker.endDate) }}
            </template>
          </date-range-picker>
        </b-card>
      </b-col>
      <b-col cols="9">
        <FacebookGroupCards :summaryData="chartSummaryData" :iconType="iconCardType" :businessType="this.businessType"/>
      </b-col>
    </b-row>
    <transition name="modal">
      <div class="modal-mask" v-if="openScheduleModal">
        <TriggerModalAll @close="closeModal" :clientID="client" @closeScheduleClientPopUp="closeScheduleClientPopUp"
          :scheduleModal="this.openScheduleModal" />
      </div>
    </transition>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BLink, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import TriggerModalAll from "@/components/client/TriggerModalAll.vue";
import FacebookGroupCards from "./FacebookGroupCards.vue";

export default {
  components: {
    TriggerModalAll,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    vSelect,
    BCardBody,
    DateRangePicker,
    FacebookGroupCards
  },
  props: {
    iconCardType: { type: String },
    slug: {
      type: String,
      required: true
    },
    client: {
      type: String,
      required: true
    },
    chartSummaryData: {
      type: Object
    },
    buttonSize: {
      type: String
    },
    iconSize: {
      type: String
    },
    businessType:{
      type:Array
    }
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0)
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)]
      };

      return ranges;
    }
  },
  data() {
    return {
      showModal: false,
      openScheduleModal: false,
      isTriggerModal: false,
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS"
      },
      opens: true,
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  methods: {
    formatDateString(originalDateString) {
      const originalDate = new Date(originalDateString);
      const formattedDate = originalDate.toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit"
      });

      return formattedDate;
    },
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let endDate = moment(values.endDate).format("YYYY-MM-DD");
      this.$emit("fetchNewData", null, null, null, null, startDate, endDate);
      this.$emit("fetchReportData", null, null, null, null, startDate, endDate);
      this.$emit("fetchProjectData", null, null, null, null, startDate, endDate);
      this.$emit(
        "fetchDateWiseNewData",
        null,
        null,
        null,
        null,
        startDate,
        endDate
      );
      this.$emit(
        "fetchZoneMetaWiseData",
        null,
        null,
        null,
        null,
        startDate,
        endDate
      );
      this.$emit(
        "fetchProductProjectMetaWiseData",
        null,
        null,
        null,
        null,
        startDate,
        endDate
      );
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// Date selector UI break fixes.
.facebookDateSelector{
  .daterangepicker .monthselect {
  margin-right: 0.2rem;
  margin-left: -1.2rem;
 }
 .daterangepicker.show-ranges.show-weeknumbers[data-v-1ebd09d2], .daterangepicker.show-ranges[data-v-1ebd09d2] {
        min-width: 840px !important;
    }
  .daterangepicker.ltr .drp-calendar.left {
        clear: left;
        margin-right: 15% !important;
  }
}
.dateContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.headerContainer {
  margin-top: 10px !important;
}

.comparison-dashboard-link {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.reportrange-text {
  display: flex;
  align-items: center;
}

.comparison {
  margin-left: auto;
}

.picker-controller {
  background-color: #f5f6fa;
  padding: 4px;
  margin-top: 6px;
}

.date-range {
  font-size: 11px !important;
}

.disable-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .headerContainer {
    display: grid;
    grid-template-columns: auto;
  }

  .headerContainerItem {
    display: flex;
    width: 350px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .comparison {
    margin-left: 0;
    width: 1150px;
  }
}

@media only screen and (max-width: 350px) {
  .headerContainer {
    display: grid;
    grid-template-columns: auto;
  }

  .headerContainerItem {
    display: flex;
    width: 250px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .comparison {
    margin-left: 0;
    width: 850px;
  }
}
</style>
