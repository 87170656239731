<template>
    <b-card class="card-tiny-line-stats" body-class="pb-50">
      <h6 class="text-xs">{{ title }}</h6>
      <h2 class="font-weight-bolder mb-1 app-title lg">
        {{ getFormattedText(summary) }}
      </h2>
      <!-- chart -->
      <vue-apex-charts height="70" :options="statisticsProfit.chartOptions" :series="statisticsProfit.series" />
    </b-card>
  </template>
  
  <script>
  import { BCard } from "bootstrap-vue";
  import VueApexCharts from "vue-apexcharts";
  import millify from "millify";
  
  const $trackBgColor = "#EBEBEB";
  
  export default {
    components: {
      BCard,
      VueApexCharts,
    },
    props: {
      title: {
        type: String,
        required: true,
        default: "",
      },
      data: {
        type: Array,
        required: true,
        default: () => {
          return [];
        },
      },
      labels: {
        type: Array,
        required: true,
        default: () => {
          return [];
        },
      },
      summary: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    data() {
      return {
        statisticsProfit: {
          series: this.data,
          chartOptions: {
            chart: {
              type: "line",
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              borderColor: $trackBgColor,
              strokeDashArray: 5,
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: -30,
                bottom: -10,
              },
            },
            stroke: {
              width: 3,
            },
            colors: ["#4D21FF"],
            markers: {
              size: 2,
              colors: "#4D21FF",
              strokeColors: "#4D21FF",
              strokeWidth: 2,
              strokeOpacity: 1,
              strokeDashArray: 0,
              fillOpacity: 1,
              discrete: [
                {
                  seriesIndex: 0,
                  dataPointIndex: this.dataPointIndex(),
                  fillColor: "#ffffff",
                  strokeColor: "#4D21FF",
                  size: 5,
                },
              ],
              shape: "circle",
              radius: 2,
              hover: {
                size: 3,
              },
            },
            xaxis: {
              labels: {
                show: true,
                style: {
                  fontSize: "0px",
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              categories: this.labels,
            },
            yaxis: {
              show: false,
            },
            tooltip: {
              x: {
                show: false,
              },
            },
          },
        },
      };
    },
    methods: {
      getFormattedText(text) {
        const title = this.title.toLowerCase();
        if (title === "conversion rate") {
          return millify(text) + " %";
        }
          return millify(text);
      },
      dataPointIndex() {
        let data = this.data[0].data;
        const max = Math.max(...data);
        const index = data.indexOf(max);
        return index;
      },
    }
  };
  </script>
  <style lang="scss">
  .card-tiny-line-stats{
  .card-body {
    padding: 1rem !important;
}
}
</style>