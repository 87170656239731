<template>
  <div>
    <b-row v-if="client.status" class="content-header">
      <b-col class="mb-1" cols="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2
              class="content-header-title float-left pr-1 mb-0 text-white"
              style="text-transform: capitalize;"
            >
              <b-avatar
                class="mr-1"
                size="32"
                :src="client.logo"
                :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `primary`"
              /> 
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top text-white"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                  class="text-white"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2">
        <div style="float: right; width: 200px;">
          <b-button
            @click="sendSchedule(client._id)"
            :size="buttonSize"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" size="12" /> Schedule a Trigger
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div>
      <div v-if="client.status && client.integrations.facebook">
        <b-overlay
          class="dashboard-overlay"
          rounded="sm"
          :show="isReportDataDateWiseLoading || isProjectDataLoad "
        >
          <FacebookDateSelector
            :client="client._id"
            :slug="$route.params.slug"
            @fetchNewData="getDateWiseReportData"
            @fetchDateWiseNewData="getReportData"
            @fetchReportData="fetchAllProjects"
            @fetchZoneMetaWiseData="fetchMetaWiseZonewiseData"
            @fetchProductProjectMetaWiseData="fetchProjectProductWiseData"
            :isDisable="true"
            :chartSummaryData="overviewData"
            :businessType="client.businessType"
              @fetchProjectData="fetchProjectWiseData"
          />
          <b-card class="tabs-container screenAlignmentFacbook p-1">
            <b-tabs fill>
              <b-tab
                title="Overview"
                active
                @click="changeTab('Graphs')"
              ></b-tab>
              <b-tab title="Project Insights" @click="changeTab('ProjectInsights')" v-if="projectsTab && client.accessConfiguration.projects.enabled" >
              </b-tab>
              <b-tab title="Group(Zone) Insights" @click="changeTab('ZoneInsights')" v-if="projectsTab && client.accessConfiguration.projects.enabled " :disabled="this.optionsLength === 0">
              </b-tab>
              <b-tab title="Product Insights" @click="changeTab('ProductInsights')" v-if="projectsTab && client.accessConfiguration.projects.enabled " :disabled="Object.keys(this.productWiseData).length === 0">
              </b-tab>
              <b-tab
                title="Campaign Report"
                @click="changeTab('CampaignReport')"
              >
              </b-tab>
              <b-tab title="Daily Report" @click="changeTab('DailyReport')">
              </b-tab>
            </b-tabs>
          </b-card>
          <FacebookOverviewedData
            v-if="
              this.tabName === 'Graphs' &&
                client.status &&
                client.integrations.facebook &&
                facebookChartData.length > 0
            "
            :isDataLoading="isReportDataLoading"
            :summaryData="overviewData"
            :chartData="facebookChartData"
            :iconCardType="iconType"
            :businessType="client.businessType"
            mediumType="aAds"
          />
          <div v-if="this.tabName === 'ProjectInsights'">
            <div v-if="
              client &&
              client.accessConfiguration &&
              client.accessConfiguration.projects.enabled
            ">
              <b-row >
                <b-col cols="12">
                  <b-card>
                    <div v-if="this.isProjectWiseIsLoad" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <MultiHeaderProjectChannel :projectName="'Project(s) Summary ' +
                        this.formatDateDisplay(this.startDateProjectName || this.selectedStartDate) +
                        ' to ' +
                        this.formatDateDisplay(this.endDateProjectName || this.selectedEndDate)
                        " v-if="
                        client &&
                        client.accessConfiguration &&
                        client.accessConfiguration.projects.enabled
                      " type="overallChannelProjectData" :businessTypeProject="client.businessType[0]"
                        :tableData="projectWiseData" :clientName="client.name"/>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- <MultiHeader :clientName="client.name" v-if="this.tabName === 'ProjectInsights'" style="margin-left:-8px; margin-right: -8px;" :tableData="projectData" type="facebookAds" :businessTypeProject="client.businessType[0]" 
          :projectNameSummary="'Project(s) Summary ' +(formatDate(this.startDateProjectName))+' to '+(formatDate(this.endDateProjectName))  " selectBoxType="Select Project(s)"
          selectionType="single"  channelType="meta"/> -->
          <b-row v-if="this.tabName === 'ZoneInsights'">
            <b-col cols="12">
           <div class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;"> 
          <MultiHeader :clientName="client.name"  channelType="meta" :zoneWiseType="zoneWiseTypeOptions" :tableData="zoneWiseData" type="zoneMetaWise" selectionType="single" :businessTypeProject="client.businessType[0]" :projectNameSummary="'Group(Zone) Insights'" selectBoxType="Select Zone(s)" @sourceChangedData="getSourceData" :zoneWiseDataLoading="isZonewiseDatafetched" @zoneTypeChangedData="getZoneTypeData"
          @handleZoneListChange="handleZoneList" @filterDataZoneWiseType="handledZoneType"  @getSubProductOptions="getSubOptions" :clientId="client._id"/>
        </div>
       </b-col>
         </b-row>
          <b-row v-if="this.tabName === 'ProductInsights'">
        <b-col cols="12">
        <div class="multiHeaderMulti"  style="margin-left:2px; margin-right: 2px;"> 
          <MultiHeader channelHideFlag="hide" hideSelectBox="meta" :clientName="client.name"  channelType="meta" :productAllProjectWiseType="productWiseTypeOptions" :tableData="productWiseData" type="productMetaWise" selectionType="multiple" :businessTypeProject="client.businessType[0]" :projectNameSummary="'Product Insights'" selectBoxType="Select Product(s)" :productWiseDataLoading="isProductDatafetched"  @handleZoneListProjectChange="handleProductProjectList" />
        </div>
        </b-col>
      </b-row>
          <data-table
            title="Campaign Report"
            v-if="this.tabName === 'CampaignReport'"
            type="facebook"
            exportsType="metaCampaign"
            :chartData="campaignsData"
            :businessType="client.businessType[0]"
          />
          <data-table
            title="Daily Report"
             exportsType="metaDaily"
            v-if="this.tabName === 'DailyReport'"
            type="facebook-daily-report"
            :chartData="datewiseData"
            :businessType="client.businessType[0]"
          />
        </b-overlay>
      </div>
    </div>
    <transition name="modal">
      <div class="modal-mask" v-if="openScheduleModal">
        <TriggerModalAll
          @close="closeScheduleClientPopUp"
          :clientID="client._id"
          @closeScheduleClientPopUp="closeScheduleClientPopUp"
          :scheduleModal="this.openScheduleModal"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
  BTab,
  BTabs
} from "bootstrap-vue";
import moment from "moment";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import MultiHeaderProjectChannel from "@/views/clientFeature/MultiheaderProjectChannel.vue";
import MultiHeader from "@/views/clientFeature/MultiHeader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TriggerModalAll from "@/components/client/TriggerModalAll.vue";
import FacebookDateSelector from "./FacebookDateSelector.vue";
import DataTable from "../GoogleAds/DataTable.vue";
import FacebookOverviewedData from "./FacebookOverviewData.vue";

import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  data() {
    return {
      selectedStartDate:"",
      selectedEndDate:"",
      productProjectNamesChange:[],
      productProjectIntialNamesChange:[],
      productWiseTypeOptions:[],
      creativeOptions:[],
      subProductOptions:"all",
      optionsLength:0,
      selectedProductType:"all",
      zoneWiseTypeOptions:null,
      zoneWiseTypeOptionsFlag:false,
      isProductDatafetched:false,
      isZonewiseDatafetched:false,
      productWiseData:[],
      zoneWiseData:[],
      startDateProjectName:"",
      endDateProjectName:"",
      datewiseData: [],
      openScheduleModal: false,
      isReportDataLoading: false,
      isReportDataDateWiseLoading: false,
      isProjectDataLoad : false,
      projectsTab: false,
      projectData:[],
      tabName: "",
      overviewData: {},
      facebookChartData: [],
      iconType: "",
      client: {
        status: false,
        integrations: {
          googleAds: null
        },
        googleAdsCustomerID: null
      },
      errorMessage: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard"
        }
      ],
      isReportDataLoading: false,
      campaignsData: [],
      charts: {
        data: [],
        totalData: {}
      },
      graphs: [],
      currentDateRange: "",
      selectedZoneTypeForFilter:[],
      zoneListChange:[],
      projectWiseData:[],
      isProjectWiseIsLoad:false
    };
  },
  props: {
    buttonSize: {
      type: String
    },
    facebookDashBoardPage: {
      type: String
    },
    googleDashBoardPage: {
      type: String
    }
  },
  components: {
    MultiHeader,
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    DataTable,
    BOverlay,
    BTab,
    BTabs,
    FacebookOverviewedData,
    TriggerModalAll,
    FacebookDateSelector,
    MultiHeaderProjectChannel,
    multiChannelSpinner
  },
  methods: {
    handleProductProjectList(data){
      this.productProjectNamesChange =  data
    },
    getSubOptions(data){
      this.subProductOptions = data
    },
    getZoneTypeData(data){
      this.selectedProductType = data
    },
    getSourceData(data){
      this.selectedSourceType = data
    },
    handleZoneList(data){
      this.zoneListChange =  data
    },
    handledZoneType(data){
      this.selectedZoneTypeForFilter = data
    },
    fetchProjectWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.showSummaryDashboardLoading = true;
      this.isProjectWiseIsLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = this.selectedStartDate
        this.endDateProjectName = this.selectedEndDate
      }
   
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/overall-project-insights?clientID=${this.client._id}&channel=meta&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.projectWiseData = response.data;
          this.isProjectWiseIsLoad = false;
        })
        .catch(error => {
          this.isProjectWiseIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    fetchMetaWiseZonewiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) 
    {
      
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.zoneWiseData = []
      this.isZonewiseDatafetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      let zoneListSeletion =  this.zoneListChange.join(',') == "" ? this.creativeOptions : this.zoneListChange.join(',') 
      let zoneData  = this.selectedZoneTypeForFilter.length === 0 ?  this.intiallOptionsForGroup  : this.selectedZoneTypeForFilter
      let subProductOption = this.subProductOptions 
      zoneListSeletion = encodeURIComponent(zoneListSeletion)
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/group/insights/?clientID=${this.client._id}${additionalQueryParams}&channel=meta&tag=Zone&product=${this.selectedProductType}&projects=${zoneListSeletion}&groupName=${zoneData}&subProductOption=${subProductOption}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.zoneWiseData = response && response.data
          this.isZonewiseDatafetched = false;
        })
        .catch(error => {
          console.log(error);
          this.isZonewiseDatafetched = false;
        });
    },
    getFetchOptions(){
      this.intiallOptionsForGroup = []
      this.zoneWiseTypeOptionsFlag = true
      axios.get(`${process.env.VUE_APP_SERVER_URL}/api/projects/options?clientID=${this.client._id}`, {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          this.zoneWiseTypeOptionsFlag = false
          this.zoneWiseTypeOptions = response && response.data
          const combinedArray = Object.values(this.zoneWiseTypeOptions.projectNames).flat();
          this.productProjectIntialNamesChange = [...combinedArray];
          this.productWiseTypeOptions = [...combinedArray]
          this.creativeOptions =  [...combinedArray];
          this.intiallOptionsForGroup = [...response && response.data && response.data.groups]
          this.optionsLength = Object.keys(response && response.data).length
        })
        .catch((error) => {
          this.zoneWiseTypeOptionsFlag = false
          console.log(error);
      });
    },
    fetchProjectProductWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) 
    {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.zoneWiseData = []
      this.isProductDatafetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate &&  this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      let projectNames = this.productProjectNamesChange.length === 0 ? this.productProjectIntialNamesChange : this.productProjectNamesChange

projectNames = encodeURIComponent(projectNames)
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/products/insights/?clientID=${this.client._id}${additionalQueryParams}&channel=meta&projects=${projectNames}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.productWiseData = response && response.data
          this.isProductDatafetched = false;
        })
        .catch(error => {
          console.log(error);
          this.isProductDatafetched = false;
        });
    },
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    changeTab(name) {
      this.tabName = name;
      if (this.tabName == 'ZoneInsights') {
        this.fetchMetaWiseZonewiseData()
      }
      if(this.tabName === "ProductInsights"){
        this.getFetchOptions();
        this.fetchProjectProductWiseData()
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant
        }
      });
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map(word => word.charAt(0).toUpperCase()).join("");
    },
    getClient() {
      const slug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.googleAdsGraphs
            ? response.data.client.googleAdsGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to:`/channels-dashboard/${response.data.client.slug}`,
            active: false
          });
          this.breadcrumbs.push({
            text: "Meta Ads Dashboard",
            to: `/client/${response.data.client.slug}/dashboard/meta-ads`,
            active: true
          });

          if (
            response.data.client &&
            response.data.client.integrations.facebook &&
            response.data.client.facebook.adAccountIDs
          ) {
            this.getFetchOptions();
            this.getReportData();
            this.getDateWiseReportData();
            this.fetchAllProjects()
            this.fetchProjectWiseData()
            setTimeout(() =>{
              this.fetchMetaWiseZonewiseData();
              this.fetchProjectProductWiseData();
            },500)
          }
        })
        .catch(error => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    getReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      let facebookAdAccountId = this.client.facebook.adAccountIDs[0];
      this.campaignsData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/get-reports?clientID=${this.client._id}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.isReportDataLoading = false;
          this.campaignsData = response.data.reports;
        })
        .catch(error => {
          this.isReportDataLoading = false;
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          // this.isReportDataLoading = false;
          this.showToast("Error", errorText, "danger");
        });
    },
    fetchAllProjects(dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null) {
        this.isProjectDataLoad = true
        this.projectData = []
        let additionalQueryParams = ``;
        let url = ''
        if (!dateRange) {
          const today = new Date();
          const yesterday = new Date(today); 
          yesterday.setDate(today.getDate() - 1); 
          const startDate = new Date(yesterday);
          startDate.setDate(yesterday.getDate() - 6);
          const formattedStartDate = startDate.toISOString().slice(0, 10);
          const formattedEndDate = yesterday.toISOString().slice(0, 10);
          additionalQueryParams += `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
          url = `${process.env.VUE_APP_SERVER_URL}/api/facebook/datewise-projects-insights/?clientID=${this.client._id}${additionalQueryParams}`
      }
      this.currentDateRange = dateRange;
      let conditionalQueryParams = ``;
      if (startDate && endDate) {
        conditionalQueryParams =
          conditionalQueryParams +
          `&startDate=${startDate}&endDate=${endDate}`;
          url = `${process.env.VUE_APP_SERVER_URL}/api/facebook/datewise-projects-insights/?clientID=${this.client._id}${conditionalQueryParams}`
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.projectData = response.data
          console.log(typeof response.data);
          this.isProjectDataLoad = false
          if (this.projectData.length) {
            this.projectsTab = true
          }
          // this.projectList = response.data.projects
        })
        .catch((error) => {
          this.isProjectDataLoad = false
          this.projectsTab = false
          console.log(error.response.data);
        });
    },
    fetchDateWiseAndCampaignData() {
      this.getReportData();
      this.getDateWiseReportData();
      this.fetchMetaWiseZonewiseData()
      this.fetchProjectProductWiseData();
    },
    formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');
    const updatedDate = `${day}-${month}-${year}`;
    return updatedDate;
   },
    getDateWiseReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataDateWiseLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !endDate) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
      }
      this.overviewData = {};
      this.datewiseData = [];
      this.facebookChartData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/get-datewise-reports/?clientID=${this.client._id}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.overviewData = response.data.totalData;
          this.datewiseData = response.data.dateFormatted;
          this.facebookChartData = response.data.reports;
          this.isReportDataDateWiseLoading = false;
        })
        .catch(error => {
          this.isReportDataDateWiseLoading = false;
          console.log(error.response.data);
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          this.showToast("Error", errorText, "danger");
        });
    },
    updateGraph(graphs) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/google-ads/add-graph`,
          { clientID: this.client._id, graphs },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.showToast("Updated", "Graph updated successfully!", "success");
        })
        .catch(error => {
          console.log(error.response);
          this.showToast("Error", "Error updating the graph", "danger");
        });
    },
    graphAdded(graphData) {
      this.graphs.push(graphData);
      this.updateGraph(this.graphs);
    },
    deleteGraph(index) {
      this.graphs.splice(index, 1);
      this.updateGraph(this.graphs);
    },
    filterReportData(filter) {
      this.getReportData(
        this.currentDateRange,
        filter.selectedCampaignType,
        filter.selectedCampaigns,
        filter.selectedAdGroups
      );
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  watch: {
    productProjectNamesChange(newVal, oldVal){
      this.productProjectNamesChange = newVal
      this.fetchProjectProductWiseData()
    },
    subProductOptions(newVal, oldVal){
      this.subProductOptions = ""
      this.subProductOptions = newVal
      this.fetchMetaWiseZonewiseData()
    },
    selectedProductType(newVal, oldVal){
      this.selectedProductType = newVal
      this.fetchMetaWiseZonewiseData()
    },
    zoneListChange(newVal, oldVal) {
      this.zoneListChange = newVal
      this.fetchMetaWiseZonewiseData()
    },
    selectedZoneTypeForFilter(newVal, oldVal) {
      this.selectedZoneTypeForFilter = newVal
      this.zoneListChange = []
      this.fetchMetaWiseZonewiseData()
    },

  },
  mounted() {
    this.tabName = "Graphs";
    this.getClient();
  }
};
</script>

<style lang="scss">
.screenAlignmentFacbook {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}

.breadcrumb-container a {
  color: antiquewhite !important;
}

.dashboard-overlay .rounded-sm {
  inset: -20px !important;
}
</style>
